<template>
  <div id="store-locator">
    <el-row class="titolo" :span="24">
      <h3>STORE LOCATOR</h3>
    </el-row>
  </div>
</template>

<script>
  import Component from "vue-class-component";
  import Vue from "vue";

  @Component({})
  class StoreLocator extends Vue {}

  export default StoreLocator;
</script>

<style>
</style>